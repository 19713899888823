import React from "react";
import PropTypes from 'prop-types';
import css from 'styled-jsx/css';
import { Button, Heading } from '@lmig/lmds-react';
import TextBody from '@lmig/dotcom-aspect-components/Utility/TextBody';
import LinkEnvironmentalize from '../LinkEnvironmentalize';

const AgentQuoteCard = ({
  heading,
  text,
  buttonText = 'Start your quote',
  alias,
  breakpoint = 'xl'
}) => {
  const { className: quoteHeadingClass, styles: quoteHeadingStyles } = css.resolve`
  @import './global-styles/tokens';
  .lm-Heading {
    margin-top: 0;
    color: #1a1446;
    font-size: 1.25rem;
    @media screen and (min-width: $lm-breakpoint-md-min) {
      margin-bottom: 1.5rem;
    }
  }
`;

  const { className: quoteSubHeadingClass, styles: quoteSubHeadingStyles } = css.resolve`
  .lm-Body {
    margin-bottom: 1.5rem;
  }`
    ;

  return (
    <div className="agentQuoteCard">
      <Heading className={quoteHeadingClass} type="h4-bold" tag="h2">
        {heading}
      </Heading>
      <TextBody className={quoteSubHeadingClass}>{text}</TextBody>
      <LinkEnvironmentalize attribution>
        <Button
          variant="primary"
          href={`/quote${alias ? `?alias=${alias}` : ''}`}
        >
          {buttonText}
        </Button>
      </LinkEnvironmentalize>
      {quoteHeadingStyles}
      {quoteSubHeadingStyles}
      <style jsx>{`
        .agentQuoteCard {
          display: flex;
          flex-flow: column;
          justify-content: center;
          text-align: center;
          align-items: center;
          background: #F2FCFC;
          padding: 2rem;
          margin-bottom: 1rem;
          width: 100vw;
          position: relative;
          left: 50%;
          right: 50%;
          margin-left: -50vw;
          margin-right: -50vw;

          @media screen and (min-width: ${breakpoint === 'xl' ? '80rem' : '60rem'}) {
            width: 100%;
            margin-left: 0;
            margin-right: 0;
            left: 0;
            right: 0;
            box-shadow: 3px 6px 18px 6px rgba(0, 0, 0, 0.1);
          }
        }  
      `}</style>
    </div>
  );
};

AgentQuoteCard.propTypes = {
  heading: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  buttonText: PropTypes.string,
  alias: PropTypes.string,
  breakpoint: PropTypes.string,
};

export default AgentQuoteCard;